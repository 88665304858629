// Generated by Framer (575e68f)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import HalogenLogoFont from "./DcSgQqg05";
import * as localizedValues from "./SCN3xtrZh-0.js";
const HalogenLogoFontFonts = getFonts(HalogenLogoFont);

const enabledGestures = {zRRF1DdTz: {hover: true}};

const cycleOrder = ["zRRF1DdTz"];

const serializationHash = "framer-RFC4T"

const variantClassNames = {zRRF1DdTz: "framer-v-1hp8k96"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {Mwo2KOPC6: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "zRRF1DdTz", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (gestureVariant === "zRRF1DdTz-hover") return true
return false
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={{webPageId: "augiA20Il"}} openInNewTab={false}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1hp8k96", className, classNames)} framer-1ym2zlr`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"zRRF1DdTz"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"zRRF1DdTz-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", intrinsicHeight: 125, intrinsicWidth: 125, pixelHeight: 250, pixelWidth: 250, src: "https://framerusercontent.com/images/sWdQVRuHZAvd8m1OGeby8Zj8.png"}} className={"framer-v4mc9b"} data-framer-name={"Pil_Change_By_Design"} layoutDependency={layoutDependency} layoutId={"u6uPMxgsl"} style={{rotate: -90}}/>{isDisplayed() && (<ComponentViewportProvider ><motion.div className={"framer-1lbd86e-container"} layoutDependency={layoutDependency} layoutId={"HQae8bvlC-container"}><HalogenLogoFont f9W3_FPaG={"var(--token-9c6bc435-8415-42c6-aa88-1bee8368b5de, rgb(249, 255, 236))"} height={"100%"} id={"HQae8bvlC"} layoutId={"HQae8bvlC"} width={"100%"}/></motion.div></ComponentViewportProvider>)}</motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-RFC4T.framer-1ym2zlr, .framer-RFC4T .framer-1ym2zlr { display: block; }", ".framer-RFC4T.framer-1hp8k96 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: hidden; padding: 0px 16px 0px 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-RFC4T .framer-v4mc9b { align-content: center; align-items: center; aspect-ratio: 1 / 1; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: var(--framer-aspect-ratio-supported, 60px); justify-content: flex-start; overflow: visible; padding: 0px; position: relative; width: 60px; }", ".framer-RFC4T .framer-1lbd86e-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-RFC4T.framer-1hp8k96, .framer-RFC4T .framer-v4mc9b { gap: 0px; } .framer-RFC4T.framer-1hp8k96 > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-RFC4T.framer-1hp8k96 > :first-child, .framer-RFC4T .framer-v4mc9b > :first-child { margin-left: 0px; } .framer-RFC4T.framer-1hp8k96 > :last-child, .framer-RFC4T .framer-v4mc9b > :last-child { margin-right: 0px; } .framer-RFC4T .framer-v4mc9b > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 60
 * @framerIntrinsicWidth 76
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"S14bwWmA1":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerSCN3xtrZh: React.ComponentType<Props> = withCSS(Component, css, "framer-RFC4T") as typeof Component;
export default FramerSCN3xtrZh;

FramerSCN3xtrZh.displayName = "Change by Design - Halogen home";

FramerSCN3xtrZh.defaultProps = {height: 60, width: 76};

addFonts(FramerSCN3xtrZh, [{explicitInter: true, fonts: []}, ...HalogenLogoFontFonts], {supportsExplicitInterCodegen: true})